export const environment = {
  production: true,
  label : "PROD",
  domain: "https://idealcareerpath.com/auth",
  enableEmailAuth: true,
  firebaseConfig : {
    apiKey: "AIzaSyD-pmMjsKNW94jqIPjqwBUzMsrhmZdJdkY",
    authDomain: "ideal-career-path-prod.firebaseapp.com",
    projectId: "ideal-career-path-prod",
    storageBucket: "ideal-career-path-prod.appspot.com",
    messagingSenderId: "866695840897",
    appId: "1:866695840897:web:3f61ae1c2b39610692f980",
    measurementId: "G-G0RJG1M0HE"
  },
  moosendConfig: {
    domain: "api.moosend.com",
    apiKey:"bad8b08d-a527-4591-b847-9b240a98e691",
    mailingListID : "10a0ea9d-421c-4c48-8aad-94aed4118703",
    format: "json",
    version: "v3",
    paths: {
      subscribers: "subscribers",
      subscribe: "subscribe"
    }
  },
  captchaConfig: {
    path: {
      domain: "www.google.com",
      api: "https://www.google.com/recaptcha/api/siteverify",
      jsScript: "https://www.google.com/recaptcha/api.js" 
    },
    publicKey: "6LcwsNEcAAAAABMiEJaOJwTLwfjPSXWhA9oPhRWL",
    secretKey: "6LcwsNEcAAAAAJqg4fiwbWrZkFGGOA-mytf_SbuR",
    options: {
      invisible: {
        sitekey: "6LcwsNEcAAAAABMiEJaOJwTLwfjPSXWhA9oPhRWL"
      },
      normal: {
        sitekey: "6LcwsNEcAAAAABMiEJaOJwTLwfjPSXWhA9oPhRWL"
      },
      language: "en"
    }
  },
  metaConfigs: {
    default: {
      "description": "Undecided About Your Career? Finding your perfect fit is a few clicks away.",
      "keywords": "career,path,ideal,students,education,skills,certificate,upskill,college",
      "author": "IdealCareerPath, LLC"
    }, 
    openGraph: {
      "og:title": "Find Your Perfect Career Fit.",
      "og:type": "website",
      "og:url": "https://idealcareerpath.com",
      "og:image": "https://idealcareerpath.com/assets/splash/success.png", 
      "og:description": "Undecided About Your Career? Finding your perfect fit is a few clicks away.",
      "og:site_name": "IdealCareerPath",
      "og:image:width": "550",
      "og:image:height": "400",
    },
    twitter: {
      "twitter:title": "Find Your Perfect Career Fit.",
      "twitter:description": "Undecided About Your Career? Finding your perfect fit is a few clicks away.",
      "twitter:card": "summary_large_image",
      "twitter:image": "https://idealcareerpath.com/assets/splash/success.png",
      "twitter:site": "@idealcareerpath"  
    }
  }
};